import { useEffect, useRef } from 'react';
import { 
    Box,
    IconButton,
} from '@mui/material';
import {
    Replay
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function FrontCamera() {

  const videoShown = useRef(null);
  const canvasShown = useRef(null);
  const videoCapture = useRef(null);

  const nav = useNavigate();

  const idealWidth = 960;
  const idealHeight = 1280;

  useEffect(() => {
    enableCam();
      
    return () => {
    // Clean up by stopping the camera stream when the component unmounts
      if (videoShown.current) {
        const stream = videoShown.current.srcObject;
        if (stream && stream instanceof MediaStream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
        }
      }

      if (videoCapture.current) {
        const stream = videoCapture.current.srcObject;
        if (stream && stream instanceof MediaStream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
        }
      }
    };

  }, []);

  function enableCam(event) {
    const constraints = {
        video: {
            facingMode: 'user',
        },
    };

    const snapshotConstraints = {
      video: {
        width: { min: idealHeight * 2, ideal: idealHeight * 2, max: idealHeight * 2 },
        height: { min: idealWidth * 2, ideal: idealWidth * 2, max: idealWidth * 2 },
        facingMode: 'user',
      },
    }
  
    // Activate the webcam stream
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      videoShown.current.srcObject = stream;
    });

    navigator.mediaDevices.getUserMedia(snapshotConstraints).then((stream) => {
      videoCapture.current.srcObject = stream;
        new Promise(resolve => {
          videoCapture.current.onloadedmetadata = () => {
              resolve(videoCapture);
            };
        }).then(() => {
          videoCapture.current.play();
        });
    })
  };

  function captureSnapshot() {
    let video = videoCapture.current;

    let idealCv = document.createElement('canvas');
    idealCv.setAttribute('width', idealWidth);
    idealCv.setAttribute('height', idealHeight);
    
    idealCv.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, idealWidth, idealHeight);

    const link = document.createElement('a');
    link.href = idealCv.toDataURL(); // Convert temporary canvas to data URL
    link.download = 'captured_image.png'; // Set the file name
    link.click();
  }

  const handleRedirectToEntry = () => {
    nav('/');
  }

  return (
    <Box 
        sx={{
            height: '100%',
            width: '100%'
        }}
    >
      <video id='show' ref={videoShown} autoPlay playsInline={true} controls='' muted
        style={{
            height: '100vh',
            transform: 'scaleX(-1)',
            width: "100vw",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
        }}
      />
      <canvas
        ref={canvasShown}
        style={{
            width: "100vw",
            height: "100vh",
            WebkitTransform: "scaleX(-1)",
            transform: 'scaleX(-1)',
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10,
            objectFit: 'cover',
        }}
      ></canvas>
      <video ref={videoCapture} muted autoPlay controls="false"  playsinline="" width={idealWidth} height={idealHeight} 
        style={{
          visibility: "hidden",
          zIndex: -100,
          position: "absolute",
          top: 0,
          left: 0,
        }} 
      >
      </video>
      <button id="button" onClick={captureSnapshot}>
        <svg width="100" height="100">
          <circle cx="50" cy="50" r="25" stroke="white" stroke-width="10" fill="none" />
          <circle cx="50" cy="50" r="10" fill="white" />
        </svg>
      </button>
      <IconButton 
        sx={{
            float: 'right',
            zIndex: 100,
        }}
        onClick={handleRedirectToEntry}
      >
        <Replay />
      </IconButton>
    </Box>
  );
}