import { 
    Box,
    Button,
    Grid,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import logo from "../images/esenca_logo_color_RGB.png";

export default function Entry() {

    const nav = useNavigate();

    const handleRedirectToBack = () => {
        nav('/back');
    };

    const handleRedirectToFront = () => {
        nav('/front');
    };

    return(
        <div className="App">
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box 
                        component={'img'}
                        src={logo}
                        sx={{
                            width: '400px'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Button variant='contained' onClick={handleRedirectToBack}>
                        Back Camera Demo
                    </Button>
                    <Button variant='contained' onClick={handleRedirectToFront}>
                        Front Camera Demo
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}